import axios from "axios";
import { API_ROOT } from "../constants";

async function getDatastores() {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT + "/datastore/" + authUser.accountId + "/datastores",
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

  // let res = {
  //   result: {
  //     dataStores: [
  //       {
  //         dataStore: "string",
  //         name: "string"
  //       }
  //     ]
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:28:27.851Z"
  // };
}

export { getDatastores };
