import React from "react";
import { Col, Label, Row } from "reactstrap";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const ServerForm = props => {
  return (
    <form action="">
      <Row className="mb-3">
        <label for="nameInput" className="col-sm-2 col-form-label">
          {props.t("serverName")}
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="nameInput"
            placeholder={props.t("enterServerName")}
          />
        </div>
      </Row>
      <Row className="mb-3">
        <label for="memoryInput" className="col-sm-2 col-form-label">
          {props.t("memorySize")}
        </label>
        <div className="col-sm-10">
          <select
            className="htmlForm-select mb-3"
            aria-label="Default select example"
            id="memoryInput">
            <option>{props.t("selectMemorySize")}</option>
            <option value="1">2</option>
            <option value="2">4</option>
            <option value="3">8</option>
            <option value="4">16</option>
            <option value="5">32</option>
          </select>
        </div>
      </Row>
    </form>
  );
};

ServerForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};
export default withRouter(withTranslation()(ServerForm));
