import axios from "axios";
import { API_ROOT } from "../constants";

async function createTicket(ticket) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(ticket),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/support/ticket", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:41:45.699Z"
  // };
}

async function answerTicket(ticket) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(ticket),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/support/answer", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:42:22.791Z"
  // };
}

async function getTickets() {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(API_ROOT + "/support/" + authUser.accountId, requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });
  // let res = {
  //   status: 200,
  //   result: {
  //     supports: [
  //       {
  //         accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //         subject: "string",
  //         supportStatus: {
  //           name: "string",
  //           enumId: 0
  //         }
  //       }
  //     ]
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:47:55.808Z"
  // };
}

async function getTicket(support) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT + "/support/" + authUser.accountId + "/" + support,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

  // let res = {
  //   result: {
  //     supports: [
  //       {
  //         accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //         subject: "string",
  //         supportStatus: {
  //           name: "string",
  //           enumId: 0
  //         }
  //       }
  //     ]
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:48:52.585Z"
  // };
}

export { createTicket, answerTicket, getTickets, getTicket };
