import { fetchWrapper } from "../fetchWrapper";
import { API_ROOT } from "../constants";
import axios from "axios";
let authUser = {};
async function login(user) {
  let obj = { username: user.username };
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(obj),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/account/login", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

}

async function verify(user, history) {
  let obj = {
    loginId: user.loginId,
    accountId: user.accountId,
    username: user.username,
    password: user.password,
    code: user.code,
  };
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(obj),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/account/verify", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log(result.result);
      console.log(result);
      console.log(result.result.sessionId);
      if (result.result.sessionId) {
        history("/servers");
        setAuthUser(user, result.result);
      }
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

}

async function logout() {
  try {
    let res = await axios.post(API_ROOT + "/account/logout", {
      username: authUser.userName,
    });
    return res;
  } catch (error) {
    console.error(error);
  }
}

async function setAuthUser(user, verifyResult) {
  authUser = {
    sessionId: verifyResult.sessionId,
    fullName: verifyResult.fullName,
    username: user.username,
    accountId: user.accountId,
  };
  sessionStorage.setItem("authUser", JSON.stringify(authUser));
}

export { login, verify, logout, setAuthUser, authUser };
