import React, { useEffect, useState } from "react";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import { osList } from "../../../store/os/list";
import { getClusters } from "../../../helpers/Server/cluster";
import { getDatastores } from "../../../helpers/Server/datastore";
import { getFolders } from "../../../helpers/Server/folder";
import { getHosts } from "../../../helpers/Server/host";
import { getResourcePools } from "../../../helpers/Server/resourcePool";
import { create } from "../../../helpers/Server/vm";
const VmForm = (props) => {
  const [vm, setVm] = useState({
    cluster: "",
    datastore: "",
    folder: "",
    resourcePool: "",
    diskSize: 64,
    displayName: "",
    memory: 128,
    operatingSystem: 32,
    userId: JSON.parse(sessionStorage.getItem("authUser")).accountId,
    host: "",
    cpuCount: 0,
    cpuCoresPerSocket: 0,
  });
  const [clusters, setClusters] = useState([]);
  const [datastores, setDatastores] = useState([]);
  const [folders, setFolders] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [resourcePools, setResourcePools] = useState([]);
  const [loading, setLoading] = useState(false);

  const fillClusters = async () => {
    let res = await getClusters();
    if (res.result !== undefined && res.result.clusters !== undefined)
      setClusters(res.result.clusters);
  };

  const fillDatastores = async () => {
    let res = await getDatastores();
    if (res.result !== undefined && res.result.dataStores !== undefined)
      setDatastores(res.result.dataStores);
  };

  const fillFolders = async () => {
    let res = await getFolders();
    if (res.result !== undefined && res.result.folders !== undefined)
      setFolders(res.result.folders);
  };

  const fillHosts = async () => {
    let res = await getHosts();
    if (res.result !== undefined && res.result.hosts !== undefined)
      setHosts(res.result.hosts);
  };

  const fillResoucePools = async () => {
    let res = await getResourcePools();
    if (res.result !== undefined && res.result.resourcePools !== undefined)
      setResourcePools(res.result.resourcePools);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    console.log(vm);
    try {
      await create(vm);
      props.tog_standard();
    } catch (error) {
      console.log(error);
    }
  };

  function countUP(att) {
    if (att === "cpuCount") setVm({ ...vm, cpuCount: vm.cpuCount + 1 });
    else if (att === "memory") {
      if (vm.memory < 4096) setVm({ ...vm, memory: vm.memory * 2 });
    } else if (att === "diskSize") {
      if (vm.diskSize < 4096) setVm({ ...vm, diskSize: vm.diskSize * 2 });
    } else if (att === "cpuCoresPerSocket")
      setVm({ ...vm, cpuCoresPerSocket: vm.cpuCoresPerSocket + 1 });
  }

  function countDown(att) {
    if (att === "cpuCount") setVm({ ...vm, cpuCount: vm.cpuCount - 1 });
    else if (att === "memory") {
      if (vm.memory > 128) setVm({ ...vm, memory: vm.memory / 2 });
    } else if (att === "diskSize") {
      if (vm.diskSize > 64) setVm({ ...vm, diskSize: vm.diskSize / 2 });
    } else if (att === "cpuCoresPerSocket")
      setVm({ ...vm, cpuCoresPerSocket: vm.cpuCoresPerSocket - 1 });
  }

  useEffect(() => {
    fillClusters();
    fillDatastores();
    fillFolders();
    fillHosts();
    fillResoucePools();
  }, []);

  return (
    <div>
      <form onSubmit={(e) => submitForm(e)}>
        <Row className="mb-3">
          <Col>
            <label htmlFor="displayName" className="form-label">
              {props.t("serverName")}
            </label>
            <input
              required
              type="text"
              className="form-control"
              id="displayName"
              onChange={(e) => setVm({ ...vm, displayName: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="os" className="form-label">
              {props.t("operatingSystem")}
            </label>
            <select
              className="form-select"
              id="os"
              required
              onChange={(e) =>
                setVm({ ...vm, operatingSystem: parseInt(e.target.value) })
              }
            >
              <option value="">{props.t("select")}</option>
              {osList.map((os, index) => (
                <option key={index} value={os.id}>
                  {os.displayName}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="cluster" className="form-label">
              {props.t("Cluster")}
            </label>
            <select
              className="form-select"
              required
              id="cluster"
              onChange={(e) => setVm({ ...vm, cluster: e.target.value })}
            >
              <option value="">{props.t("select")}</option>
              {clusters.map((cluster, index) => (
                <option key={index} value={cluster}>
                  {cluster}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="dataStore" className="form-label">
              {props.t("Datastore")}
            </label>
            <select
              className="form-select"
              required
              id="dataStore"
              onChange={(e) => setVm({ ...vm, datastore: e.target.value })}
            >
              <option value="">{props.t("select")}</option>
              {datastores.map((datastore, index) => (
                <option key={index} value={datastore.dataStore}>
                  {datastore.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="folder" className="form-label">
              {props.t("Folder")}
            </label>
            <select
              className="form-select"
              required
              id="folder"
              onChange={(e) => setVm({ ...vm, folder: e.target.value })}
            >
              <option value="">{props.t("select")}</option>
              {folders.map((folder, index) => (
                <option key={index} value={folder}>
                  {folder}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="resourcePool" className="form-label">
              {props.t("Resource Pool")}
            </label>
            <select
              className="form-select"
              required
              id="resourcePool"
              onChange={(e) => setVm({ ...vm, resourcePool: e.target.value })}
            >
              <option value="">{props.t("select")}</option>
              {resourcePools.map((resourcePool, index) => (
                <option key={index} value={resourcePool}>
                  {resourcePool}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label htmlFor="host" className="form-label">
              {props.t("Host")}
            </label>
            <select
              className="form-select"
              id="host"
              onChange={(e) => setVm({ ...vm, host: e.target.value })}
            >
              <option value="">{props.t("select")}</option>
              {hosts.map((host, index) => (
                <option key={index} value={host.host}>
                  {host.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col mb="3">
            <h5 className="fs-13 fw-medium">{props.t("Cpu")}</h5>
            <div className="input-step full-width">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown("cpuCount");
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={vm.cpuCount}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP("cpuCount");
                }}
              >
                +
              </button>
            </div>
          </Col>
          <Col mb="6">
            <h5 className="fs-13 fw-medium">{props.t("Disk")}</h5>
            <div className="input-step full-width">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown("diskSize");
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={vm.diskSize}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP("diskSize");
                }}
              >
                +
              </button>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col mb="6">
            <h5 className="fs-13 fw-medium">{props.t("Memory")}</h5>
            <div className="input-step full-width">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown("memory");
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={vm.memory}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP("memory");
                }}
              >
                +
              </button>
            </div>
          </Col>
          <Col mb="6">
            <h5 className="fs-13 fw-medium">{props.t("Core")}</h5>
            <div className="input-step full-width">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown("cpuCoresPerSocket");
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                value={vm.cpuCoresPerSocket}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUP("cpuCoresPerSocket");
                }}
              >
                +
              </button>
            </div>
          </Col>
        </Row>
        <Row className="row mb-3">
          <Col md={12}>
            <div className="text-end">
              {!loading ? (
                <Button type="submit" className="btn btn-success">
                  {props.t("Submit")}
                </Button>
              ) : (
                <button
                  className="btn-load btn btn-success btn-label right ms-auto"
                  type="button"
                >
                  <span className="d-flex align-items-center ">
                    <Spinner size="sm" className="flex-shirink-0">
                      Loading...
                    </Spinner>
                    <span className="flex-grow-1 ms-2">Loading...</span>
                  </span>
                </button>
              )}
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
VmForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(VmForm));
