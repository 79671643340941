import { React, useEffect, useState } from "react";
import { getAll } from "../../../helpers/Server/vm";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
const List = (props) => {
  return (
    <div className="card p-2 shadow" id="todo-task">
      <div
        className="table-responsive"
        style={{ overflow: "auto", maxHeight: "calc(100vh - 386px)" }}
      >
        {props.loading ? (
          <table className="table align-middle position-relative table-nowrap ">
            <thead className="table-active">
              <tr>
                <th scope="col">{props.t("Server Name")}</th>
                <th scope="col">{props.t("Source")}</th>
                <th scope="col">{props.t("Tag")}</th>
                <th scope="col">{props.t("Status")}</th>
                <th scope="col">{props.t("Process")}</th>
              </tr>
            </thead>
            <tbody id="task-list">
              {props.vms.map((vm, index) => (
                <tr key={index}>
                  <td>{vm.name}</td>
                  <td></td>
                  <td>
                    <span className="badge badge-soft-warning text-uppercase">
                      Uygulama Sunucuları
                    </span>
                  </td>
                  <td>
                    <span
                      className={`badge text-uppercase ${
                        vm.powerState === "POWERED_ON"
                          ? "bg-success"
                          : "bg-danger"
                      }`}
                    >
                      {vm.powerState}
                    </span>
                  </td>
                  <td>
                    <div className="hstack gap-2">
                      {/* <button
                        className="btn btn-sm btn-soft-danger remove-list"
                        data-bs-toggle="modal"
                        data-bs-target="#removeTaskItemModal"
                        data-remove-id="15"
                      >
                        <i className="ri-delete-bin-5-fill align-bottom"></i>
                      </button> */}
                      <button
                        onClick={() => props.toggleDetails(vm)}
                        className="btn btn-sm btn-soft-info edit-list"
                      >
                        {props.t("seeDetails")}
                      </button>
                      {/* <button
                        className="btn btn-sm btn-soft-info edit-list"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        data-edit-id="15"
                      >
                        <i className="ri-pencil-fill align-bottom"></i>
                      </button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "40vh",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
List.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(List));
