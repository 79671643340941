import axios from "axios";
import { API_ROOT } from "../constants";
import { fetchWrapper } from "../fetchWrapper";
async function create(obj) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(obj),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/vm", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: {
  //     vmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     name: "string",
  //     displayName: "string",
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-14T10:26:12.616Z",
  // };
}

async function getAll() {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(API_ROOT + "/vm/" + authUser.accountId + "/vms", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: {
  //     vms: [
  //       {
  //         vm: "string",
  //         name: "string",
  //         powerState: "string",
  //         memory: 0,
  //         disk: 0,
  //         os: 0,
  //         cpuCount: 0,
  //         coresCount: 0
  //       }
  //     ]
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-13T11:10:48.855Z"
  // };
}

async function get(name) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT + "/vm/" + authUser.accountId + "/" + name,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: {
  //     vm: {
  //       vm: "string",
  //       name: "string",
  //       powerState: "string",
  //       memory: 0,
  //       disk: 0,
  //       os: 0,
  //       cpuCount: 0,
  //       coresCount: 0
  //     }
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-13T14:31:17.799Z"
  // };
}

async function update(id, updatedObj) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(updatedObj),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/vm/" + id, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

}

async function power(vm, powerState) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      accountId: authUser.accountId,
      power: powerState,
      vm: vm,
    }),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/vm/power/", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });

}

export { create, getAll, get, update, power };
