import React, { useEffect, useState } from "react";
import { getAll } from "../../../helpers/Server/vm";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { osList } from "../../../store/os/list";
import cpuIcon from "../../../assets/images/ico/computer/cpu.png";
import memoryIcon from "../../../assets/images/ico/computer/memory.png";
import hddIcon from "../../../assets/images/ico/computer/hdd.png";

const Cards = (props) => {
  console.log(props.vms);
  return (
    <Row>
      <Col className="co12">
        <Row
          className="row-cols-xxl-4 row-cols-lg-4 row-cols-1"
          style={{ overflow: "auto", maxHeight: "calc(100vh - 346px)" }}
        >
          {props.loading ? (
            props.vms.map((vm, index) => (
              <Col key={index}>
                <Card className="server-card">
                  <CardBody>
                    <div className="d-flex mb-4 align-items-center">
                      <div className="flex-shrink-0">
                        <i
                          style={{ fontSize: "48px" }}
                          className=" ri-centos-line text-danger"
                        ></i>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <h5 className="card-title mb-1 fw-lighter">
                          {vm.name}
                        </h5>
                        <p className="text-muted mb-0">
                          {osList.filter((x) => x.id === vm.os)[0]
                            ? osList.filter((x) => x.id === vm.os)[0]
                                .displayName
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                      <div className="col-md-4">
                        <img
                          src={cpuIcon}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                        <p>{vm.cpuCount} vCPU</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src={memoryIcon}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                        <p>{vm.memory} GB</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          src={hddIcon}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                        <p>{vm.disk} GB</p>
                      </div>
                    </div>
                    {/* <div className="properties mb-1">
                    <i className="ri-cpu-line text-primary"></i>
                    <span className="text-muted fw-lighter">{vm.cpu}</span>
                    <i className="las la-memory text-success  ms-1"></i>
                    <span className="text-muted fw-lighter">{vm.memory}</span>
                    <i className="mdi mdi-harddisk  text-warning ms-1"></i>
                    <span className="text-muted fw-lighter">{vm.disk}</span>
                  </div> */}
                    <div className="d-grid">
                      <button
                        onClick={() => props.toggleDetails(vm)}
                        className="btn btn-primary btn-sm"
                      >
                        {props.t("seeDetails")}
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "40vh",
              }}
            >
              <Spinner />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};
Cards.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Cards));
