import axios from "axios";
import { API_ROOT } from "../constants";


async function getLogsByVm(vm) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  console.log(authUser.accountId);
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT +
      "/account/" +
      authUser.accountId +
      "/logs/" +
      vm +
      "?start=0&end=25",
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  // let res = {
  //   result: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-18T10:19:16.405Z"
  // };
}

async function getLogsByUser() {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT + "/account/" + authUser.accountId + "/logs/?start=1&end=25",
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  // let res = {
  //   result: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-18T10:19:16.405Z"
  // };
}
export { getLogsByVm, getLogsByUser };
