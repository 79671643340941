import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getTags } from "../../../helpers/account/tag";
import { useState } from "react";
import { useEffect } from "react";
import { osList } from "../../../store/os/list";
import { getAll } from "../../../helpers/Server/vm";
const Labels = (props) => {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [tags, setTags] = useState([]);
  const [osTags, setOsTags] = useState([]);
  const fillTags = async () => {
    let result = await getTags();
    if (result.result !== undefined) setTags(result.result.tags);
  };
  const fillOsList = async () => {
    let tVms = await getAll();
    const uniqueOsValues = new Set(tVms.result.vms.map((vm) => vm.os));
    const uniqueOsArray = [...uniqueOsValues];
    console.log(uniqueOsArray);
    setOsTags(uniqueOsArray);
  };
  useEffect(() => {
    console.log(authUser.sessionId);
    if (authUser.sessionId) {
      fillOsList();
      fillTags();
    }
  }, [authUser.sessionId]);
  return (
    <ul className="to-do-menu list-unstyled" id="projectlist-data">
      <li>
        <Link to="#" className="nav-link fs-13" id="labels_1">
          {props.t("labelList")}
        </Link>
        <UncontrolledCollapse toggler={"#labels_1"}>
          <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
            <li>
              <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
                {tags.map((tag, index) => (
                  <li key={index}>
                    <a href="#!">
                      <i className="ri-stop-mini-fill align-middle fs-15 text-danger"></i>
                      {tag.value}
                    </a>
                  </li>
                ))}
                {/* <li>
                  <a href="#!">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-danger"></i>{" "}
                    {props.t("Tüm Sunucular")}
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-secondary"></i>{" "}
                    {props.t("Uygulama Sunucuları")}
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-info"></i>{" "}
                    {props.t("Test Sunucuları")}
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-primary"></i>{" "}
                    {props.t("DB Sunucuları")}
                  </a>
                </li> */}
                <li>
                  <a href="#!">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-warning"></i>{" "}
                    {props.t("Etiketsiz Sunucular")}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </UncontrolledCollapse>
      </li>
      <li>
        <Link to="#" className="nav-link fs-13" id="labels_2">
          {props.t("operationSystem")}
        </Link>
        <UncontrolledCollapse toggler={"#labels_2"}>
          <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
            <li>
              <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
                {Array.isArray(osTags) &&
                  osTags.map((os, index) => (
                    <li key={index}>
                      <a href={"?osId="+os}>
                        <i className="ri-stop-mini-fill align-middle fs-15 text-danger"></i>
                        {osList.filter((x) => x.id === os)[0]
                          ? osList.filter((x) => x.id === os)[0].displayName
                          : ""}
                      </a>
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </UncontrolledCollapse>
      </li>
      <li>
        <Link to="#" className="nav-link fs-13" id="labels_3">
          {props.t("workingStatus")}
        </Link>
        <UncontrolledCollapse toggler={"#labels_3"}>
          <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
            <li>
              <ul className="mb-0 sub-menu list-unstyled ps-3 vstack gap-2 mb-2">
                <li>
                  <a href="?serverState=POWERED_ON">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-success"></i>{" "}
                    {props.t("Açık Sunucular")}
                  </a>
                </li>
                <li>
                  <a href="?serverState=POWERED_OFF">
                    <i className="ri-stop-mini-fill align-middle fs-15 text-danger"></i>{" "}
                    {props.t("Kapalı Sunucular")}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </UncontrolledCollapse>
      </li>
    </ul>
  );
};
Labels.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Labels));
