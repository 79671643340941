import { React, useState, useCallback, useEffect } from "react";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import ServerForm from "./ServerForm";
import PropTypes from "prop-types";
import cpuIcon from "../../../assets/images/ico/computer/cpu.png";
import ramIcon from "../../../assets/images/ico/computer/memory.png";
import hddIcon from "../../../assets/images/ico/computer/hdd.png";
import { get, power } from "../../../helpers/Server/vm";
import { osList } from "../../../store/os/list";
import DeleteModal from "../../../Components/Common/DeleteModal";
import LogList from "./LogList";

const Details = (props) => {
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    command: 0,
    message: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [vm, setVm] = useState({
    coresCount: 0,
    cpuCount: 0,
    disk: 0,
    memory: 0,
    name: "",
    os: 0,
    powerState: "",
    vm: "",
  });

  const [powerChanged, setPowerChanged] = useState(true);
  const changePowerStatus = async (powerState) => {
    setPowerChanged(false);
    let res = await power(props.selectedVm.vm, powerState);
    fillVm();
    setPowerChanged(true);
  };
  const fillVm = async () => {
    if (props.selectedVm.vm) {
      let res = await get(props.selectedVm.vm);
      console.log(res.result.vm);
      if (res.result !== undefined) setVm(res.result.vm);
    }
  };

  const handleDeleteTicket = (e) => {
    changePowerStatus(e);
    setDeleteModal(false);
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);
  const onClickDelete = () => {
    setDeleteModal(true);
  };
  useEffect(() => {
    fillVm();
  }, [props.selectedVm, powerChanged]);

  return (
    <div>
      <Offcanvas
        style={{ width: "40%" }}
        fade
        isOpen={props.show}
        direction="end"
        id="offcanvasServerDetail"
        scrollable
        toggle={function noRefCheck() {}}
      >
        <OffcanvasHeader
          id="offcanvasServerDetailLabel"
          toggle={props.onCloseClick}
          className="border-bottom"
        >
          {vm.name}
          <button className="btn btn-sm btn-soft-info">
            <i className="ri-pencil-fill align-bottom"></i>
          </button>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  <img
                    src=""
                    alt=""
                    className="flex-shirink-0 me-3 avatar-xl"
                  />
                </Col>
                <Col md={9}>
                  <table>
                    <tbody>
                      <tr>
                        <th>{props.t("Operating System")}</th>
                        <td>
                          {osList.filter((x) => x.id === props.selectedVm.os)[0]
                            ? osList.filter(
                                (x) => x.id === props.selectedVm.os
                              )[0].displayName
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <th>{props.t("Tag")}</th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <div className="mt-2">
                  {powerChanged ? (
                    <button
                      disabled={vm.powerState === "POWERED_OFF" ? false : true}
                      type="button"
                      // onClick={(e) => changePowerStatus(1)}
                      onClick={(e) =>
                        setDeleteModal({
                          status: true,
                          command: 1,
                          message: "Do you want to start this vm",
                        })
                      }
                      className="btn btn-sm btn-soft-success btn-label waves-effect waves-light me-2"
                    >
                      <i className="ri-play-fill label-icon align-middle"></i>
                      {props.t("Run")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-soft-success btn-label waves-effect waves-light me-2"
                      type="button"
                    >
                      <span className="d-flex align-items-center">
                        <Spinner size="sm" className="flex-shirink-0"></Spinner>
                        <span className="flex-grow-1 ms-2"></span>
                      </span>
                    </button>
                  )}
                  {powerChanged ? (
                    <button
                      disabled={vm.powerState === "POWERED_ON" ? false : true}
                      // onClick={(e) => changePowerStatus(4)}
                      type="button"
                      onClick={(e) =>
                        setDeleteModal({
                          status: true,
                          command: 4,
                          message: "Do you want to close this vm",
                        })
                      }
                      className="btn btn-sm btn-soft-danger btn-label waves-effect waves-light me-2"
                    >
                      <i className="ri-stop-circle-line  label-icon align-middle"></i>
                      {props.t("Shut Down")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-soft-danger btn-label waves-effect waves-light me-2"
                      type="button"
                    >
                      <span className="d-flex align-items-center">
                        <Spinner size="sm" className="flex-shirink-0"></Spinner>
                        <span className="flex-grow-1 ms-2"></span>
                      </span>
                    </button>
                  )}
                  {powerChanged ? (
                    <button
                      disabled={vm.powerState === "POWERED_OFF" ? true : false}
                      // onClick={(e) => changePowerStatus(3)}
                      onClick={(e) =>
                        setDeleteModal({
                          status: true,
                          command: 3,
                          message: "Do you want to restart this vm",
                        })
                      }
                      type="button"
                      className="btn btn-sm btn-soft-secondary btn-label waves-effect waves-light me-2"
                    >
                      <i className="ri-restart-line  label-icon align-middle"></i>
                      {props.t("Restart")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-soft-secondary btn-label waves-effect waves-light me-2"
                      type="button"
                    >
                      <span className="d-flex align-items-center">
                        <Spinner size="sm" className="flex-shirink-0"></Spinner>
                        <span className="flex-grow-1 ms-2"></span>
                      </span>
                    </button>
                  )}
                  {powerChanged ? (
                    <button
                      // onClick={(e) => changePowerStatus(2)}
                      disabled={vm.powerState === "POWERED_ON" ? false : true}
                      onClick={(e) =>
                        setDeleteModal({
                          status: true,
                          command: 2,
                          message: "Do you want to suspend this vm",
                        })
                      }
                      type="button"
                      className="btn btn-sm btn-soft-warning btn-label waves-effect waves-light me-2"
                    >
                      <i className="ri-pause-circle-line  label-icon align-middle"></i>
                      {props.t("Suspend")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-soft-warning btn-label waves-effect waves-light me-2"
                      type="button"
                    >
                      <span className="d-flex align-items-center">
                        <Spinner size="sm" className="flex-shirink-0"></Spinner>
                        <span className="flex-grow-1 ms-2"></span>
                      </span>
                    </button>
                  )}
                </div>
              </Row>
            </div>
          </div>
          <Row className="mt-2">
            <Col xl={4}>
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <img
                        src={cpuIcon}
                        alt=""
                        className="avatar-sm rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                        CPU
                      </p>

                      <div className="d-flex align-items-center mb-3">
                        <h4 className="fs-4 flex-grow-1 mb-0">
                          <span className="counter-value" data-target="825">
                            {vm.cpuCount} vCPU
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <img
                        src={ramIcon}
                        alt=""
                        className="avatar-sm rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                        RAM
                      </p>

                      <div className="d-flex align-items-center mb-3">
                        <h4 className="fs-4 flex-grow-1 mb-0">
                          <span className="counter-value" data-target="825">
                            {vm.memory} GB
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <img
                        src={hddIcon}
                        alt=""
                        className="avatar-sm rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                        HDD 1
                      </p>

                      <div className="d-flex align-items-center mb-3">
                        <h4 className="fs-4 flex-grow-1 mb-0">
                          <span className="counter-value" data-target="825">
                            {vm.disk} GB
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {props.selectedVm.vm && <LogList vm={props.selectedVm.vm} />}
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      <DeleteModal
        message={props.t(deleteModal.message)}
        buttonName="Yes"
        show={deleteModal.status}
        command={deleteModal.command}
        onDeleteClick={(e) => handleDeleteTicket(e)}
        onCloseClick={() => setDeleteModal(false)}
      />
    </div>
  );
};
Details.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Details));
