const osList = [
  { id: 1, displayName: "MS-DOS." },
  { id: 2, displayName: "Windows 3.1" },
  { id: 3, displayName: "Windows 95" },
  { id: 4, displayName: "Windows 98" },
  { id: 5, displayName: "Windows Millennium Edition" },
  { id: 6, displayName: "Windows NT 4" },
  { id: 7, displayName: "Windows 2000 Professional" },
  { id: 8, displayName: "Windows 2000 Server" },
  { id: 9, displayName: "Windows 2000 Advanced Server" },
  { id: 10, displayName: "Windows XP Home Edition" },
  { id: 11, displayName: "Windows XP Professional" },
  { id: 12, displayName: "Windows XP Professional Edition (64 bit)" },
  { id: 13, displayName: "Windows Server 2003, Web Edition" },
  { id: 14, displayName: "Windows Server 2003, Standard Edition" },
  { id: 15, displayName: "Windows Server 2003, Enterprise Edition" },
  { id: 16, displayName: "Windows Server 2003, Datacenter Edition" },
  { id: 17, displayName: "Windows Small Business Server 2003" },
  { id: 18, displayName: "Windows Server 2003, Standard Edition (64 bit)" },
  { id: 19, displayName: "Windows Server 2003, Enterprise Edition (64 bit)" },
  { id: 20, displayName: "Windows Longhorn" },
  { id: 21, displayName: "Windows Longhorn (64 bit)" },
  { id: 22, displayName: "Windows Server 2003, Datacenter Edition (64 bit)" },
  { id: 23, displayName: "Windows Vista" },
  { id: 24, displayName: "Windows Vista (64 bit)" },
  { id: 25, displayName: "Windows 7" },
  { id: 26, displayName: "Windows 7 (64 bit)" },
  { id: 27, displayName: "Windows Server 2008 R2 (64 bit)" },
  { id: 28, displayName: "Windows 8" },
  { id: 29, displayName: "Windows 8 (64 bit)" },
  { id: 30, displayName: "Windows 8 Server (64 bit)" },
  { id: 31, displayName: "Windows 10" },
  { id: 32, displayName: "Windows 10 (64 bit)" },
  { id: 33, displayName: "Windows 10 Server (64 bit)" },
  { id: 34, displayName: "Windows 11 (64 bit)" },
  { id: 35, displayName: "Windows 12 (64 bit)" },
  { id: 36, displayName: "Windows Hyper-V" },
  { id: 37, displayName: "Windows Server 2019" },
  { id: 38, displayName: "Windows Server 2022" },
  { id: 39, displayName: "Windows Server 2025" },
  { id: 40, displayName: "FreeBSD 10 or earlier" },
  { id: 41, displayName: "FreeBSD 10 x64 or earlier" },
  { id: 42, displayName: "FreeBSD 11" },
  { id: 43, displayName: "FreeBSD 12" },
  { id: 44, displayName: "FreeBSD 13" },
  { id: 45, displayName: "FreeBSD 14 or later" },
  { id: 46, displayName: "FreeBSD 11 x64" },
  { id: 47, displayName: "FreeBSD 12 x64" },
  { id: 48, displayName: "FreeBSD 13 x64" },
  { id: 49, displayName: "FreeBSD 14 x64 or later" },
  { id: 50, displayName: "Red Hat Linux 2.1" },
  { id: 51, displayName: "Red Hat Enterprise Linux 2" },
  { id: 52, displayName: "Red Hat Enterprise Linux 3" },
  { id: 53, displayName: "Red Hat Enterprise Linux 3 (64 bit)" },
  { id: 54, displayName: "Red Hat Enterprise Linux 4" },
  { id: 55, displayName: "Red Hat Enterprise Linux 4 (64 bit)" },
  { id: 56, displayName: "Red Hat Enterprise Linux 5" },
  { id: 57, displayName: "Red Hat Enterprise Linux 5 (64 bit)" },
  { id: 58, displayName: "Red Hat Enterprise Linux 6" },
  { id: 59, displayName: "Red Hat Enterprise Linux 6 (64 bit)" },
  { id: 60, displayName: "Red Hat Enterprise Linux 7" },
  { id: 61, displayName: "Red Hat Enterprise Linux 7 (64 bit)" },
  { id: 62, displayName: "Red Hat Enterprise Linux 8 (64 bit)" },
  { id: 63, displayName: "Red Hat Enterprise Linux 9 (64 bit)" },
  { id: 64, displayName: "CentOS 4⁄5" },
  { id: 65, displayName: "CentOS 4⁄5 (64-bit)" },
  { id: 66, displayName: "CentOS 6" },
  { id: 67, displayName: "CentOS 6 (64-bit)" },
  { id: 68, displayName: "CentOS 7" },
  { id: 69, displayName: "CentOS 7 (64-bit)" },
  { id: 70, displayName: "CentOS 8 (64-bit)" },
  { id: 71, displayName: "CentOS 9 (64-bit)" },
  { id: 72, displayName: "Oracle Linux 4⁄5" },
  { id: 73, displayName: "Oracle Linux 4⁄5 (64-bit)" },
  { id: 74, displayName: "Oracle Linux 6" },
  { id: 75, displayName: "Oracle Linux 6 (64-bit)" },
  { id: 76, displayName: "Oracle Linux 7" },
  { id: 77, displayName: "Oracle Linux 7 (64-bit)" },
  { id: 78, displayName: "Oracle Linux 8 (64-bit)" },
  { id: 79, displayName: "Oracle Linux 9 (64-bit)" },
  { id: 80, displayName: "Suse Linux" },
  { id: 81, displayName: "Suse Linux (64 bit)" },
  { id: 82, displayName: "Suse Linux Enterprise Server 9" },
  { id: 83, displayName: "Suse Linux Enterprise Server 9 (64 bit)" },
  { id: 84, displayName: "Suse linux Enterprise Server 10" },
  { id: 85, displayName: "Suse Linux Enterprise Server 10 (64 bit)" },
  { id: 86, displayName: "Suse linux Enterprise Server 11" },
  { id: 87, displayName: "Suse Linux Enterprise Server 11 (64 bit)" },
  { id: 88, displayName: "Suse linux Enterprise Server 12" },
  { id: 89, displayName: "Suse Linux Enterprise Server 12 (64 bit)" },
  { id: 90, displayName: "Suse Linux Enterprise Server 15 (64 bit)" },
  { id: 91, displayName: "Suse Linux Enterprise Server 16 (64 bit)" },
  { id: 92, displayName: "Novell Linux Desktop 9" },
  { id: 93, displayName: "Open Enterprise Server" },
  { id: 94, displayName: "Sun Java Desktop System" },
  { id: 95, displayName: "Mandrake Linux" },
  { id: 96, displayName: "Mandriva Linux" },
  { id: 97, displayName: "Mandriva Linux (64 bit)" },
  { id: 98, displayName: "Turbolinux" },
  { id: 99, displayName: "Turbolinux (64 bit)" },
  { id: 100, displayName: "Ubuntu Linux" },
  ,
  { id: 101, displayName: "Ubuntu Linux (64 bit)" },
  { id: 102, displayName: "Debian GNU/Linux 4" },
  { id: 103, displayName: "Debian GNU/Linux 4 (64 bit)" },
  { id: 104, displayName: "Debian GNU/Linux 5" },
  { id: 105, displayName: "Debian GNU/Linux 5 (64 bit)" },
  { id: 106, displayName: "Debian GNU/Linux 6" },
  { id: 107, displayName: "Debian GNU/Linux 6 (64 bit)" },
  { id: 108, displayName: "Debian GNU/Linux 7" },
  { id: 109, displayName: "Debian GNU/Linux 7 (64 bit)" },
  { id: 110, displayName: "Debian GNU/Linux 8" },
  { id: 111, displayName: "Debian GNU/Linux 8 (64 bit)" },
  { id: 112, displayName: "Debian GNU/Linux 9" },
  { id: 113, displayName: "Debian GNU/Linux 9 (64 bit)" },
  { id: 114, displayName: "Debian GNU/Linux 10" },
  { id: 115, displayName: "Debian GNU/Linux 10 (64 bit)" },
  { id: 116, displayName: "Debian GNU/Linux 11" },
  { id: 117, displayName: "Debian GNU/Linux 11 (64 bit)" },
  { id: 118, displayName: "Debian GNU/Linux 12" },
  { id: 119, displayName: "Debian GNU/Linux 12 (64 bit)" },
  { id: 120, displayName: "Asianux Server 3" },
  { id: 121, displayName: "Asianux Server 3 (64 bit)" },
  { id: 122, displayName: "Asianux Server 4" },
  { id: 123, displayName: "Asianux Server 4 (64 bit)" },
  { id: 124, displayName: "Asianux Server 5 (64 bit)" },
  { id: 125, displayName: "Asianux Server 7 (64 bit)" },
  { id: 126, displayName: "Asianux Server 8 (64 bit)" },
  { id: 127, displayName: "Asianux Server 9 (64 bit)" },
  { id: 128, displayName: "OpenSUSE Linux" },
  { id: 129, displayName: "OpenSUSE Linux (64 bit)" },
  { id: 130, displayName: "Fedora Linux" },
  { id: 131, displayName: "Fedora Linux (64 bit)" },
  { id: 132, displayName: "CoreOS Linux (64 bit)" },
  { id: 133, displayName: "VMware Photon (64 bit)" },
  { id: 134, displayName: "Linux 2.4x Kernel" },
  { id: 135, displayName: "Linux 2.4x Kernel (64 bit)" },
  { id: 136, displayName: "Linux 2.6x Kernel" },
  { id: 137, displayName: "Linux 2.6x Kernel (64 bit)" },
  { id: 138, displayName: "Linux 3.x Kernel" },
  { id: 139, displayName: "Linux 3.x Kernel (64 bit)" },
  { id: 140, displayName: "Linux 4.x Kernel" },
  { id: 141, displayName: "Linux 4.x Kernel (64 bit)" },
  { id: 142, displayName: "Linux 5.x Kernel" },
  { id: 143, displayName: "Linux 5.x Kernel (64 bit)" },
  { id: 144, displayName: "Linux 6.x Kernel" },
  { id: 145, displayName: "Linux 6.x Kernel (64 bit)" },
  { id: 146, displayName: "Linux 2.2x Kernel" },
  { id: 147, displayName: "Other Linux" },
  { id: 148, displayName: "Linux (64 bit)" },
  { id: 149, displayName: "Solaris 6" },
  { id: 150, displayName: "Solaris 7" },
  { id: 151, displayName: "Solaris 8" },
  { id: 152, displayName: "Solaris 9" },
  { id: 153, displayName: "Solaris 10 (32 bit)" },
  { id: 154, displayName: "Solaris 10 (64 bit)" },
  { id: 155, displayName: "Solaris 11 (64 bit)" },
  { id: 156, displayName: "OS/2" },
  { id: 157, displayName: "eComStation 1.x" },
  { id: 158, displayName: "eComStation 2.0" },
  { id: 159, displayName: "Novell NetWare 4" },
  { id: 160, displayName: "Novell NetWare 5.1" },
  { id: 161, displayName: "Novell NetWare 6.x" },
  { id: 162, displayName: "SCO OpenServer 5" },
  { id: 163, displayName: "SCO OpenServer 6" },
  { id: 164, displayName: "SCO UnixWare 7" },
  { id: 165, displayName: "Mac OS 10.5" },
  { id: 166, displayName: "Mac OS 10.5 (64 bit)" },
  { id: 167, displayName: "Mac OS 10.6" },
  { id: 168, displayName: "Mac OS 10.6 (64 bit)" },
  { id: 169, displayName: "Mac OS 10.7" },
  { id: 170, displayName: "Mac OS 10.7 (64 bit)" },
  { id: 171, displayName: "Mac OS 10.8 (64 bit)" },
  { id: 172, displayName: "Mac OS 10.9 (64 bit)" },
  { id: 173, displayName: "Mac OS 10.10 (64 bit)" },
  { id: 174, displayName: "Mac OS 10.11 (64 bit)" },
  { id: 175, displayName: "Mac OS 10.12 (64 bit)" },
  { id: 176, displayName: "Mac OS 10.13 (64 bit)" },
  { id: 177, displayName: "Mac OS 10.14 (64 bit)" },
  { id: 178, displayName: "Mac OS 10.15 (64 bit)" },
  { id: 179, displayName: "Mac OS 11 (64 bit)" },
  { id: 180, displayName: "Mac OS 12 (64 bit)" },
  { id: 181, displayName: "Mac OS 13 (64 bit)" },
  { id: 182, displayName: "Mac OS 14 (64 bit)" },
  { id: 183, displayName: "VMware ESX 4" },
  { id: 184, displayName: "VMware ESX 5" },
  { id: 185, displayName: "VMware ESX 6" },
  { id: 186, displayName: "VMware ESXi 6.5 AND ESXi 6.7." },
  { id: 187, displayName: "VMware ESX 7" },
  { id: 188, displayName: "VMware ESX 8" },
  { id: 189, displayName: "Amazon Linux 2 (64 bit)" },
  { id: 190, displayName: "Amazon Linux 3 (64 bit)" },
  { id: 191, displayName: "CRX Pod 1" },
  { id: 192, displayName: "Rocky Linux (64-bit)" },
  { id: 193, displayName: "AlmaLinux (64-bit)" },
  { id: 194, displayName: "Other Operating System" },
  { id: 195, displayName: "Other Operating System (64 bit)" }
];
export { osList };
