import axios from "axios";
import { API_ROOT } from "../constants";

async function createTag(tagName) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ accountId: authUser.accountId, tagName: tagName }),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/account/tag", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-18T10:19:16.405Z"
  // };
}

async function getTag(tag) {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({
      accountId: authUser.accountId,
      tagId: tag.tagId,
      tagName: tag.tagName,
    }),
    redirect: "follow",
  };

  return fetch(API_ROOT + "/account/tag" + tag.tagName, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = await axios.patch(
  //   API_ROOT + "/account/tag/" + tag.tagName,
  //   { accountId: authUser.accountId, tagId: tag.tagId, tagName: tag.tagName },
  //   {
  //     headers: { "X-VMWARE-SESSION-ID": authUser.sessionId },
  //   }
  // );
  // let res = {
  //   result: true,
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-18T10:20:56.153Z"
  // };
}

async function getTags() {
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  console.log(authUser);
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-VMWARE-SESSION-ID", authUser.sessionId);

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    API_ROOT + "/account/" + authUser.accountId + "/tags",
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error
    });


  // let res = {
  //   result: {
  //     tags: [
  //       {
  //         key: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //         value: "string"
  //       }
  //     ]
  //   },
  //   errorMessage: "string",
  //   timeGenerated: "2023-09-18T10:23:22.761Z"
  // };
}

export { createTag, getTag, getTags };
