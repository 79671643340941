import React from "react";
import { useState, useEffect } from "react";
import { getLogsByUser, getLogsByVm } from "../../../helpers/account/logs";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
const LogList = (props) => {
  const [logs, setLogs] = useState([]);
  console.log(props.vm);

  const fillLogs = async () => {
    let tLogs = await getLogsByVm(props.vm);
    console.log(tLogs.result.logs);
    setLogs(tLogs.result.logs);
  };
  useEffect(() => {
    fillLogs();
  }, [props.vm]);
  return (
    
    <Table className="table align-middle table-nowrap mb-0">
      <thead className="table-light">
        <tr>
          <th>{props.t("Source")}</th>
          <th>{props.t("Message")}</th>
          <th>{props.t("Referance")}</th>
          <th>{props.t("Status")}</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(logs) &&
          logs.map((log, index) => (
            <tr key={index}>
              <td>{log.source}</td>
              <td>{log.message}</td>
              <td>{log.reference}</td>
              <td className="align-center">
                {log.isSuccess ? (
                  <i className="ri ri-checkbox-line fs-17 1h-1 align-middle text-success"></i>
                ) : (
                  <i className="ri ri-error-warning-line fs-17 1h-1 align-middle text-danger"></i>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

LogList.propTypes = {
  vm: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(LogList));
