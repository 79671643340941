import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown
} from "reactstrap";

import DeleteModal from "../../../Components/Common/DeleteModal";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getTickets } from "../../../helpers/Support/ticket";
import { Link } from "react-router-dom";
import { createTicket } from "../../../helpers/Support/ticket";
const TicketsData = props => {
  const [tickets, setTickets] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [ticket, setTicket] = useState({
    accountId: "",
    groupId: "",
    subject: "",
    message: ""
  });

  // Delete Tickets
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);
  const fillTickets = async () => {
    let res = await getTickets();
    if (res.status === 200) setTickets(res.result.supports);
  };
  useEffect(() => {
    fillTickets();
  }, []);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTicket(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleDeleteTicket = () => {
    if (ticket) {
      // dispatch(deleteTicket(ticket._id));
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach(element => {
      // dispatch(deleteTicket(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const submit = async e => {
    e.preventDefault();
    setModal(false);
    await createTicket(ticket);
  };
  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTicket}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">
                  {props.t("Tickets")}
                </h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <button
                      className="btn btn-danger add-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className="ri-add-line align-bottom"></i>{" "}
                      {props.t("Create Ticket")}
                    </button>
                    {isMultiDeleteButton && (
                      <button
                        className="btn btn-soft-danger"
                        onClick={() => setDeleteModalMulti(true)}>
                        <i className="ri-delete-bin-2-line"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CardHeader>

            <CardBody className="pt-0">
              <div className="table-responsive table-card mt-3 mb-1">
                <table className="table align-middle table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th className="sort" data-sort="id">
                        {props.t("Id")}
                      </th>
                      <th className="sort" data-sort="customer_name">
                        {props.t("Subject")}
                      </th>
                      <th className="sort" data-sort="status">
                        {props.t("Status")}
                      </th>
                      <th className="sort" data-sort="action">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {tickets.map((ticket, index) => (
                      <tr key={index}>
                        <td className="id">
                          <Link
                            to={"/support/" + 1}
                            className="fw-medium link-primary">
                            #VZ2101
                          </Link>
                        </td>
                        <td className="customer_name">{ticket.subject}</td>
                        <td className="status">
                          <span className="badge badge-soft-success text-uppercase">
                            {ticket.supportStatus.name}
                          </span>
                        </td>
                        <td>
                          {/* <div className="d-flex gap-2">
                            <div className="edit">
                              <button
                                className="btn btn-sm btn-success edit-item-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#showModal">
                                Edit
                              </button>
                            </div>
                            <div className="remove">
                              <button
                                className="btn btn-sm btn-danger remove-item-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteRecordModal">
                                Remove
                              </button>
                            </div>
                          </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="noresult" style={{ display: "none" }}>
                  <div className="text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      trigger="loop"
                      colors="primary:#121331,secondary:#08a88a"
                      style={{ width: "75px", height: "75px" }}></lord-icon>
                    <h5 className="mt-2">Sorry! No Result Found</h5>
                    <p className="text-muted mb-0">
                      We've searched more than 150+ Orders We did not find any
                      orders for you search.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="pagination-wrap hstack gap-2">
                  <Link className="page-item pagination-prev disabled" to="#">
                    Previous
                  </Link>
                  <ul className="pagination listjs-pagination mb-0"></ul>
                  <Link className="page-item pagination-next" to="#">
                    Next
                  </Link>
                </div>
              </div>
              {/* {tickets.length ? (
                <TableContainer
                  columns={columns}
                  data={tickets || []}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  handleTicketClick={handleTicketsClicks}
                  isTicketsListFilter={true}
                  SearchPlaceholder="Search for ticket details or something..."
                />
              ) : (
                <Loader error={""} />
              )}
              <ToastContainer closeButton={false} limit={1} /> */}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="border-0"
        modalClassName="zoomIn">
        <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
          {!!isEdit ? props.t("Edit Ticket") : props.t("Create Ticket")}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={e => submit(e)} className="tablelist-form">
            <Row className="mb-2">
              <Col lg={12}>
                <Label htmlFor="subject" className="form-label">
                  {props.t("Subject")}
                </Label>
                <Input
                  name="subject"
                  id="subject"
                  className="form-control"
                  type="text"
                  required
                  onChange={e =>
                    setTicket({ ...ticket, subject: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12}>
                <Label htmlFor="message" className="form-label">
                  {props.t("Message")}
                </Label>
                <textarea
                  id="message"
                  required
                  className="form-control"
                  onChange={e =>
                    setTicket({ ...ticket, message: e.target.value })
                  }
                  style={{ minHeight: "240px" }}></textarea>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn">
                    {props.t("Submit")}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
TicketsData.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};
export default withRouter(withTranslation()(TicketsData));
