import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import Details from "./ServerDetail/Details";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import withRouter from "../../Components/Common/withRouter";
import Cards from "./CardView/Cards";
import Labels from "./Labels/Labels";
import List from "./listView/List";
import VmForm from "./Forms/VmForm";
import DeleteModal from "../../Components/Common/DeleteModal";
import { getAll } from "../../helpers/Server/vm";

const Server = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [CardView, setCardView] = useState(true);
  const [isServerDetails, setIsServerDetails] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [selectedVm, setSelectedVm] = useState({});
  const [vms, setVms] = useState([]);
  const [loading, setLoading] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  const toggleDetails = (vm) => {
    setSelectedVm(vm);
    setIsServerDetails(!isServerDetails);
  };
  let authUser = JSON.parse(sessionStorage.getItem("authUser"));
  const fillVms = async () => {
    let tVms = await getAll();
    if (tVms.result !== undefined && tVms.result.vms !== undefined) {
      if (searchParams.get("serverState")) {
        let filtered = tVms.result.vms.filter(
          (x) => x.powerState === searchParams.get("serverState")
        );
        setVms(filtered);
      } else if (searchParams.get("osId")) {
        console.log(searchParams.get("osId"));
        let filtered = tVms.result.vms.filter(
          (x) => x.os === parseInt(searchParams.get("osId"))
        );
        console.log(filtered);
        setVms(filtered);
      } else setVms(tVms.result.vms);
    }
    setLoading(true);
  };
  useEffect(() => {
    if (authUser.sessionId) fillVms();
  }, [authUser.sessionId, searchParams]);
  const search = async (e) => {
    let tVms = await getAll();
    let filtered = tVms.result.vms.filter((post) => {
      return post.name.toLowerCase().match(e.target.value.toLowerCase());
    });
    setVms(filtered);
  };
  document.title = props.t("Servers") + " | Incloud";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div
              className="file-manager-sidebar"
              style={{ height: "calc(100vh - 183px)" }}
            >
              <div className="p-4 d-flex flex-column h-100">
                <div
                  className="px-4 mx-n4"
                  data-simplebar
                  style={{ height: "calc(100vh - 238px)" }}
                >
                  <Labels vms={vms} />
                </div>

                {/* <div className="row server-card">
                  <div className="properties mb-1">
                    <i className="ri-cpu-line text-primary"></i>
                    <span className="text-muted fw-lighter">16v CPU</span>
                    <i className="las la-memory text-success  ms-1"></i>
                    <span className="text-muted fw-lighter">32 GB</span>
                    <i className="mdi mdi-harddisk  text-warning ms-1"></i>
                    <span className="text-muted fw-lighter">300 GB</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="w-100 p-4 pb-0">
              <Row className="mb-3">
                <div className="col-auto order-1 d-block d-lg-none">
                  <button
                    type="button"
                    className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn"
                  >
                    <i className="ri-menu-2-fill align-bottom"></i>
                  </button>
                </div>
                <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                  <h3 className="mb-0 fw-lighter">{props.t("serverList")}</h3>
                </div>

                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        id="switchGridView"
                        onClick={() => setCardView(true)}
                        // {`wrapper searchDiv ${this.state.something}`}
                        className={
                          "btn btn-icon fw-semibold  " +
                          (CardView ? "btn-success" : "btn-soft-success")
                        }
                      >
                        <i className="ri-grid-fill"></i>
                      </button>
                      <button
                        id="switchListView"
                        onClick={() => setCardView(false)}
                        className={
                          "btn btn-icon fw-semibold  " +
                          (!CardView ? "btn-success" : "btn-soft-success")
                        }
                      >
                        <i className="ri-align-justify"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Row>
              <div className="p-3 bg-light shadow rounded mb-3">
                <Row className="g-2">
                  <Col className="col-lg">
                    <div className="search-box">
                      <input
                        type="text"
                        id="searchTaskList"
                        className="form-control search"
                        placeholder={props.t("searchServerNameIpOp")}
                        onChange={(e) => search(e)}
                        // onKeyUp={e => searchList(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  <Col className="col-lg-auto">
                    <button
                      className="btn btn-primary createTask"
                      type="button"
                      onClick={(e) => tog_standard()}
                      // onClick={() => handleTodoClicks()}
                    >
                      <i className="ri-add-fill align-bottom" />{" "}
                      {props.t("addServer")}
                    </button>
                  </Col>
                </Row>
              </div>
              {CardView ? (
                <Cards
                  vms={vms}
                  loading={loading}
                  toggleDetails={toggleDetails}
                />
              ) : (
                <List
                  vms={vms}
                  loading={loading}
                  toggleDetails={toggleDetails}
                />
              )}
            </div>
          </div>
        </Container>
      </div>
      {selectedVm.vm && (
        <Details
          show={isServerDetails}
          onCloseClick={() => setIsServerDetails(false)}
          selectedVm={selectedVm}
        />
      )}
      <Modal
        id="createVmModal"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader
          className="modal-title"
          id="staticBackdropLabel"
          toggle={() => {
            tog_standard(false);
          }}
        >
          {props.t("addServer")}
        </ModalHeader>
        <ModalBody>
          <VmForm tog_standard={() => tog_standard(false)} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

Server.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Server));
