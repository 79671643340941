import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";

import logoLight from "../../assets/images/inCloudLogo-light.png";

import withRouter from "../../Components/Common/withRouter";
import { login, verify } from "../../helpers/Authentication/acess";
import { useState } from "react";
import { API_ROOT } from "../../helpers/constants";
const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    loginId: "",
    accountId: "",
    username: "",
    password: "",
    code: "",
  });
  const [loginResult, setLoginResult] = useState({
    result: "",
    errorMsg: "",
    timeGenerated: "",
  });
  const [verifyResult, setVerifyResult] = useState({
    result: "",
    errorMsg: "",
    timeGenerated: "",
  });
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  const _login = async (e) => {
    e.preventDefault();
    setLoading(true);
    let tLoginResult = await login(user);
    setLoginResult(tLoginResult);
    console.log(tLoginResult);
    try {
      if (
        tLoginResult.result !== undefined &&
        tLoginResult.result.accountId !== undefined
      ) {
        setUser({
          ...user,
          loginId: tLoginResult.result.loginId,
          accountId: tLoginResult.result.accountId,
        });
        toggleTab(activeTab + 1, 50);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const _veryfy = async (e) => {
    e.preventDefault();
    setLoading(true);
    let tVerifyResult = await verify(user, props.router.navigate);
    setLoading(false);
  };
  document.title = "Incloud | Control Panel";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="20" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">Incloud Control Panel</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Incloud control panel.
                      </p>
                    </div>
                    {/* {errorMsg && errorMsg ? (
                      <Alert color="danger"> {errorMsg} </Alert>
                    ) : null} */}
                    <Nav
                      className="nav-pills progress-bar-tab custom-nav"
                      role="tablist"
                    ></Nav>
                    <div className="p-2 mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <form
                            onSubmit={(e) => {
                              _login(e);
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              <Input
                                required
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={(e) =>
                                  setUser({ ...user, username: e.target.value })
                                }
                              />
                            </div>
                            <div className="d-flex align-items-start gap-3 mt-4">
                              {loading ? (
                                <button className="btn-load btn btn-success btn-label right ms-auto" type="button">
                                  <span className="d-flex align-items-center ">
                                    <Spinner
                                      size="sm"
                                      className="flex-shirink-0"
                                    >
                                      Loading...
                                    </Spinner>
                                    <span className="flex-grow-1 ms-2">
                                      Loading...
                                    </span>
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                  Continue
                                </button>
                              )}
                            </div>
                          </form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <form
                            onSubmit={(e) => {
                              _veryfy(e);
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Password
                              </Label>
                              <Input
                                name="password"
                                className="form-control"
                                type="password"
                                required
                                onChange={(e) =>
                                  setUser({ ...user, password: e.target.value })
                                }
                                // value={validation.values.email || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Code
                              </Label>
                              <Input
                                name="sms"
                                className="form-control"
                                type="number"
                                required
                                onChange={(e) =>
                                  setUser({ ...user, code: e.target.value })
                                }
                                // value={validation.values.email || ""}
                              />
                            </div>
                            {loading ? (
                              <button className="btn-load btn btn-success w-100" type="button">
                                <span className="d-flex align-items-center">
                                  <Spinner size="sm" className="flex-shirink-0">
                                    Loading...
                                  </Spinner>
                                  <span className="flex-grow-1 ms-2">
                                    Loading...
                                  </span>
                                </span>
                              </button>
                            ) : (
                              <Button
                                // disabled={error ? null : loading ? true : false}
                                color="success"
                                className="btn btn-success w-100"
                                type="submit"
                              >
                                Sign In
                              </Button>
                            )}
                          </form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
